import moment, { isMoment } from 'moment'
moment.locale('es')

export default class DateHelper {
  constructor (fecha) {
    this.fecha = fecha
  }

  fechaDentroDeRango (fechaDesde, fechaHasta, fecha) {
    fecha = fecha || this.fecha
    return (DateHelper.formatoCalendario(fecha) >= DateHelper.formatoCalendario(fechaDesde) && DateHelper.formatoCalendario(fecha) <= DateHelper.formatoCalendario(fechaHasta))
  }

  static fechaMayorQue (fecha1, fecha2, puedeSerIgual) {
    puedeSerIgual = puedeSerIgual || true
    if (puedeSerIgual) {
      return this.formatoCalendario(fecha1) >= this.formatoCalendario(fecha2)
    } else {
      return this.formatoCalendario(fecha1) > this.formatoCalendario(fecha2)
    }
  }
  static fechaMenorQue (fecha1, fecha2, puedeSerIgual) {
    puedeSerIgual = puedeSerIgual || true
    if (puedeSerIgual) {
      return this.formatoCalendario(fecha1) <= this.formatoCalendario(fecha2)
    } else {
      return this.formatoCalendario(fecha1) < this.formatoCalendario(fecha2)
    }
  }
  static fechasIguales (fecha1, fecha2) {
    return moment(fecha1).isSame(fecha2)
  }

  static addToDate (fecha, valor, tipo) {
    tipo = tipo || 'days'
    return moment(fecha).add(valor, tipo)
  }
  static substractToDate (fecha, valor, tipo) {
    tipo = tipo || 'days'
    return moment(fecha).subtract(valor, tipo)
  }

  static formatoFechaAmigable (fecha) {
    return DateHelper.formatoCalendario(fecha, 'DD-MMM-YYYY')
  }
  static formatoCalendario (fecha, format) {
    format = format || 'YYYY-MM-DD'

    if (typeof fecha === 'object') {
      if (!isMoment(fecha)) {
        let year = fecha.getFullYear()
        let month = fecha.getMonth() + 1
        let day = fecha.getDate()
        fecha = year + '-' + month + '-' + day
      }
    }

    return moment(fecha).format(format)
  }

  static isEmpty (fecha) {
    return fecha === undefined || fecha === null || fecha === '' || fecha === '0000-00-00'
  }

  static emptyDapteValidate (date, text) {
    return (DateHelper.isEmpty(date)) ? text : date
  }

  static validValue (value) {
    return (DateHelper.isEmpty(value)) ? '' : value
  }
}
